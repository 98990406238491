import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import { Picture, Text } from '@components/ui'
import { Cta, Price } from '@components/product'
import { useState, useEffect, useRef } from 'react'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { Discount, CountDown } from '../FlashSaleCharger'
import { normalizeStorefrontProduct as normalize } from '@components/normalize'

import cn from 'classnames'
import s from './index.module.css'
import useCoupons from '@lib/hooks/useCoupon'
import useProduct from '@shopify/product/use-product'

const Index = ({ pageHandle, data, dataKey, category }) => {
  const targetRef = useRef(null)
  const { locale } = useRouter()
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isImpression, setIsImpression] = useState(false)

  const { data: result } = useProduct({
    handles: data?.products?.map((product) => product?.handle).join(','),
  })

  const { coupons } = useCoupons(data.products?.map((p) => p.sku))

  const products = data.products
    ?.map((product) => normalize(product, result, coupons, data))
    ?.filter((p) => p)

  useEffect(() => {
    if (targetRef.current) {
      const targetPosition =
        targetRef.current.getBoundingClientRect().top + window.pageYOffset
      const windowHeight = window.innerHeight
      // 判断是否滚动到目标元素的位置
      if (
        scrollPosition > targetPosition - windowHeight &&
        !isImpression &&
        products &&
        scrollPosition
      ) {
        // 在此执行您的操作
        pageGTMEvent({ event_parameters: null })

        let items = products.map((item, index) => {
          return {
            item_id: item?.sku,
            item_name: item?.label,
            item_brand: item?.brand,
            item_category: item?.category,
            item_variant: atobID(item?.variantId),
            price: item?.price,
            index: index, //表示该产品在列表中的展示顺序
          }
        })
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'view_item_list',
          event_parameters: {
            page_group:
              pageHandle == 'home'
                ? 'Home Page'
                : 'Product Detail Page_' + pageHandle,
            position: '', //未特殊说明时可不要
            currency: products[0]?.currencyCode,
            item_list_id: '', //未特殊说明时可不要
            item_list_name: category
              ? 'flash_sale_collection'
              : pageHandle == 'home'
              ? 'flash_sale_home'
              : 'flash_sale_listing', //传该模块的小标题
            items,
          },
        })
        setIsImpression(true)
      }
    }
  }, [isImpression, scrollPosition, data, products, pageHandle])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleJump = (item, index) => {
    pageGTMEvent({ event_parameters: null })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group:
          pageHandle == 'home'
            ? 'Home Page'
            : 'Product Detail Page_' + pageHandle,
        action: 'Img/Title_' + '', //可不要
        position: '', //未特殊说明时可不要
        currency: products[0]?.currencyCode,
        item_list_id: '', //未特殊说明时可不要
        item_list_name: category
          ? 'flash_sale_collection'
          : pageHandle == 'home'
          ? 'flash_sale_home'
          : 'flash_sale_listing', //传该模块的小标题
        items: [
          {
            item_id: item?.sku,
            item_name: item?.label,
            item_brand: item?.brand,
            item_category: item?.category,
            item_variant: atobID(item?.variantId),
            price: item?.price,
            index: index, //表示该产品在列表中的展示顺序
          },
        ],
      },
    })
  }

  return (
    <section
      className={cn('layer', s.flashSaleStable, `${dataKey}`)}
      style={{ background: data?.bgColor || '#F5F5F5' }}
      ref={targetRef}
    >
      <style>
        {data?.isTheme &&
          `
        .${s.flashSaleStable} .primary {
          background-color: #FF9128;
          border: #FF9128;
        }
        .${s.flashSaleStable} .mask {
          background-color: #FFA24A;
        }
        .${s.flashSaleStable} .secondary {
          color: #000;
          background-color: white;
          border: 1px solid #FF9128;
        }
        .${s.flashSaleStable} .secondary:hover {
          color: white;
        }
        .${s.flashSaleStable} .countDown {
          align-items: flex-start;
        }

        .${dataKey}.${s.flashSaleStable} .col {
          display: grid;
          grid-template-columns: repeat(${data?.row || 2}, minmax(0, 1fr));
        }
    
        @media (max-width:769px) {
          .${s.flashSaleStable} .countDown {
            order: 2;
            padding-bottom: 0;
            align-items: center;
          }
        }
      `}
      </style>
      <div className="content">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-end gap-[20px] md:gap-[10px]">
            {data?.imgLeft && (
              <Picture
                className="h-[90px] w-[75px] md:h-[37px] md:w-[31px]"
                source={`${data?.imgLeft}`}
              />
            )}
            <Text
              html={data?.title}
              className="text-center text-[40px] font-semibold md:text-[24px]"
              variant="sectionHeading"
            />
            {data?.imgRight && (
              <Picture
                className="h-[90px] w-[75px] md:h-[37px] md:w-[31px]"
                source={`${data?.imgRight}`}
              />
            )}
          </div>

          {data?.subtitle && (
            <Text
              html={data?.subtitle}
              className="text-center text-[24px] font-medium text-[#666] md:text-[16px]"
              variant="paragraph"
            />
          )}
        </div>

        <ul className={cn(s.col, 'col')}>
          {products?.map((item, index) => (
            <li
              key={index}
              className={cn('flex gap-[29px] md:flex-col', {
                [s.row]: data?.row == 1,
              })}
            >
              <a
                onClick={() => handleJump(item, index)}
                className={cn(s.imgWrap, { [s.imgRow]: data?.row == 1 })}
                href={`${locale == 'us' ? '' : '/' + locale}/products/${
                  item?.handle
                }?ref=${
                  data?.ref || `${pageHandle}-${dataKey}`
                }&variant=${atobID(item?.variantId)}`}
              >
                <Discount data={data} product={item} />
                <Picture
                  className="flex h-full w-full"
                  source={`${item?.image}, ${item?.mobileImage} 769`}
                />
              </a>

              <div className="flex h-full flex-1 flex-col items-start justify-center bg-[#fff] md:items-center md:justify-start">
                {item?.endTime && <CountDown item={item} data={data} />}

                <Text
                  html={item?.title}
                  className="pb-[14px] text-[20px] font-medium text-[#666] md:order-[1] md:flex-1 md:px-[20px] md:text-center md:text-[18px]"
                  variant="paragraph"
                />

                <div className="flex justify-center gap-[5px] pb-[5px] font-semibold md:order-2 md:py-[15px]">
                  <Price
                    product={item}
                    copy={data}
                    s={{
                      price: 'text-[32px] text-[#FF9128] md:text-[32px]',
                      basePrice:
                        'text-[#BABABA] text-[25px] line-through md:text-[25px]',
                    }}
                  />
                </div>

                <Cta
                  s={s}
                  copy={data}
                  products={[item]}
                  pageHandle={pageHandle}
                  gtmParam={{
                    itemListName: category
                      ? 'flash_sale_collection'
                      : pageHandle == 'home'
                      ? 'flash_sale_home'
                      : 'flash_sale_listing', //传该模块的小标题
                  }}
                  buttons={
                    item?.buttons ||
                    data?.buttons || [{ type: 'buyNow', theme: 'primary' }]
                  }
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Index
