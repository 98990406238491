import { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Image, { ImageProps } from 'next/image'
import cn from 'classnames'
import { Text } from '@components/ui'
import Button from '@components/control/Button'
import { ColorSwatch } from '@components/product'
import { atobID, getProductTag } from '@lib/utils/tools'
import usePrice from '@shopify/product/use-price'
import Picture from '@components/ui/Picture'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { ProductCardPop } from '@components/product'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/autoplay'

import s from './ProductCard.module.css'

const ProductCard = ({
  product,
  imgProps,
  className,
  text = {},
  model = 'simple',
  sales,
  index,
  metafields = {},
  coupons = [],
  pageHandle = '',
  pageRef = '',
  compareBtnTxt = '',
  compareProducts = [],
  setCompareProducts = null,
  hrefTarget = 'self',
  autoTestTag = '',
}) => {
  const { copywriting = {} } = metafields || {}
  const { solixFlexSkusInfo = {} } = copywriting || {}
  const { setRelativeLink, setHtmlRelativeLink } = useRelativeLink()

  const { locale } = useRouter()
  const currentVariant = useMemo(() => {
    if (product?.filter_variants?.length > 0) {
      return (
        product.filter_variants.find((p) => p.availableForSale) ||
        product.filter_variants[0]
      )
    }
    return (
      product?.variants?.find((p) => p.availableForSale) ||
      product?.variants?.[0]
    )
  }, [product])
  const coupon = coupons?.find((i) => i.sku === currentVariant.sku) || {}

  const solixFlexSku = useMemo(() => {
    return solixFlexSkusInfo?.[currentVariant?.sku]
  }, [currentVariant?.sku, solixFlexSkusInfo])

  const { price } = usePrice({
    amount: Number(currentVariant.price),
    currencyCode: product.price.currencyCode,
  })

  const { price: discount_price } = usePrice({
    amount: Number(product?.variant_price4wscode || 0),
    currencyCode: product.price.currencyCode,
  })

  const { price: sale_price } = usePrice({
    amount: Number(coupon?.variant_price4wscode),
    currencyCode: product.price.currencyCode,
  })

  const rootClassName = cn(
    s.ProductCardRoot,
    { [s.simple]: model === 'filter' || model === 'series_filter' },
    { [s.ProductCardRootV2]: model === 'filterV2' },
    className
  )

  const availableForSale = useMemo(() => {
    return model === 'filter' ||
      model === 'series_filter' ||
      model === 'filterV2'
      ? product?.availableForSale
      : currentVariant?.availableForSale
  }, [currentVariant?.availableForSale, model, product?.availableForSale])

  // 在商品列表页点击图片Learn more, 星级等能够跳转到商品详情页的事件
  const handleBuyPushLayerClick = useCallback(() => {
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group: 'Product Detail Page_' + pageHandle,
        action: '', //可不要
        position: '',
        currency: product?.price?.currencyCode,
        item_list_id: '',
        item_list_name: 'collections_' + pageHandle,
        items: [
          {
            item_id: product?.variants?.[0]?.sku,
            item_name: product?.name,
            item_brand: product?.vendor,
            item_category: product?.productType,
            item_variant: product?.variants?.[0]?.name,
            price: product?.price?.value,
            index: index,
          },
        ],
      },
    })
  }, [product, index])

  const getRef = (model) => {
    if (model === 'series_filter') {
      return '&ref=seriesBuy'
    }
    if (model === 'filter') {
      return '&ref=collectionBuy'
    }
    if (model === 'filterV2') {
      return pageRef
    }
    return ''
  }

  const tag = product.tags?.find((tag) => tag in (text?.tagToDescription || {}))
  const [hover, setHover] = useState(false)
  const [openProductPop, setOpenProductPop] = useState(false)

  // console.log('tag:', tag)
  // console.log('tagToDescription:', text.tagToDescription)

  const hoverProSence = useMemo(() => {
    if (currentVariant?.metafields?.infos?.hoverProSence)
      return currentVariant?.metafields?.infos?.hoverProSence
    if (Array.isArray(currentVariant?.metafields?.gallery?.list)) {
      return currentVariant?.metafields?.gallery?.list?.find(
        (i) => i?.type == 'scene'
      )?.images?.[0]?.url
    }
  }, [currentVariant])

  return (
    <>
      <a
        className={rootClassName}
        aria-label={product.name}
        onClick={handleBuyPushLayerClick}
        href={setRelativeLink({
          link: `${locale === 'us' ? '' : `/${locale}`}/products/${
            product.handle
          }?variant=${atobID(currentVariant.id)}${getRef(model)}`,
        })}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        target={hrefTarget}
      >
        {(model === 'filter' || model === 'series_filter') && (
          <>
            <div className={s.imageContainer}>
              <Picture
                alt={product.name || 'Product Image'}
                className={s.productImage}
                source={currentVariant?.images?.url || product.images[0]?.url}
                {...imgProps}
              />
            </div>
            <div className={s.infoBox}>
              <Text
                html={
                  text.tagToDescription?.[tag]?.label ||
                  text.tagToDescription?.[tag] ||
                  ''
                }
                className="min-h-[28px] pb-1 text-base font-normal text-anker-color"
                variant="paragraph"
              />
              <div className={s.nameBox}>
                <Text
                  autoTestTag={autoTestTag}
                  html={product.name}
                  className={cn(s.name, { [s.hover]: hover })}
                  variant="paragraph"
                />
                {hover && (
                  <div className={s.swatch}>
                    {product.options &&
                      product.options.map((opt) => {
                        if (opt.displayName == 'color') {
                          return opt.values.map((v, i) => {
                            return (
                              <ColorSwatch
                                key={`${opt.id}-${i}`}
                                imagePath={product.images[0]?.url}
                                className={s.color}
                                color={v.hexColors ? v.hexColors[0] : v.label}
                              />
                            )
                          })
                        }
                        return ''
                      })}
                  </div>
                )}
              </div>
              {availableForSale ? (
                <div className={s.price}>
                  <p>
                    <span className="pr-2">{text?.from}</span>
                    <span className="text-xl font-semibold">{price}</span>
                  </p>
                  {hover && (
                    <Button
                      onClick={handleBuyPushLayerClick}
                      className={cn(s.learnmore)}
                      href={setRelativeLink({
                        link: `/products/${product.handle}`,
                      })}
                    >
                      {text?.buy}
                    </Button>
                  )}
                </div>
              ) : (
                <div className={s.price}>
                  <span className="pr-2 font-medium text-gray-300">
                    {text?.soldOut}
                  </span>
                </div>
              )}
            </div>
            {sales && (
              <div className="absolute -left-0.5 top-0 h-[53px] w-[60px] text-[0.8rem] leading-[1.5rem] text-white md:h-[44px]  md:w-[50px]">
                <p className="relative left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 break-words px-1 py-[2px] text-center leading-[1.2] md:text-[12px]">
                  {sales}
                </p>
                {text?.saleBg && (
                  <Picture
                    alt="sales"
                    className="absolute left-0 top-0 w-full object-fill"
                    source={text?.saleBg}
                    src={text?.saleBg}
                  />
                )}
              </div>
            )}
          </>
        )}

        {model === 'custom_api' && (
          <>
            <div className={s.imageContainer}>
              <Image
                alt={product?.product_title || 'Product Image'}
                className={s.productImage}
                src={product?.variant_image || ''}
                fill
                {...imgProps}
              />
            </div>
            <div className={s.infoBox}>
              <p className="mb-1 hidden text-base font-semibold text-[#ff9128] md:block">
                {sales}
              </p>
              <div className={cn(s.name, 'min-h-[66px]')}>
                <div className={cn(s.hoverSwatch, s.block)}>
                  <span
                    className={s.line}
                    dangerouslySetInnerHTML={{ __html: product?.product_title }}
                  />
                </div>
              </div>
              <div className={s.price}>
                <p className="mr-2">
                  <span className="mr-1 text-xl font-semibold text-brand-color">
                    {discount_price}
                  </span>
                  <span className="text-base text-gray-600 line-through">
                    {price}
                  </span>
                </p>
                <Button
                  onClick={handleBuyPushLayerClick}
                  className={cn(s.hover)}
                  href={setRelativeLink({
                    link: `/products/${product.handle}`,
                  })}
                >
                  {text?.buy || 'buy'}
                </Button>
              </div>
            </div>

            {sales && (
              <div className="absolute -left-0.5 top-0 h-[53px] w-[60px] text-base text-white md:hidden  md:h-[44px] md:w-[50px]">
                <p className="relative left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 break-words px-1 py-[2px] text-center font-semibold leading-[1.2] md:text-sm ">
                  {sales}
                </p>
                {text?.saleBg && (
                  <Picture
                    alt="sales"
                    className="absolute left-0 top-0 w-full object-fill"
                    source={text?.saleBg}
                    src={text?.saleBg}
                  />
                )}
              </div>
            )}
          </>
        )}

        {model === 'filterV2' && (
          <div className={s.filterV2}>
            <div className={s.imageContainer}>
              <Picture
                alt={product.name || 'Product Image'}
                className={cn(
                  s.productImage,
                  `${hoverProSence ? 'md:hidden' : ''}`
                )}
                source={currentVariant?.images?.url || product.images[0]?.url}
                {...imgProps}
              />
              {hoverProSence && hover && (
                <Picture
                  source={hoverProSence}
                  imgClassName={cn(
                    'w-full h-full !object-cover',
                    `${hoverProSence ? 'md:hidden' : ''}`
                  )}
                  className="z-[2] h-full w-full !object-cover"
                />
              )}
              {hoverProSence && (
                <div className="h-full w-full min-md:hidden">
                  <Swiper
                    className="h-full w-full"
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    speed={300}
                  >
                    <SwiperSlide
                      key="hoverProSence0"
                      className="box-border h-full w-full"
                    >
                      <Picture
                        alt={product.name || 'Product Image'}
                        source={
                          currentVariant?.images?.url || product.images[0]?.url
                        }
                        {...imgProps}
                        className={cn(s.productImage)}
                        imgClassName="h-full w-full"
                      />
                    </SwiperSlide>
                    <SwiperSlide
                      key="hoverProSence1"
                      className="box-border h-full w-full"
                    >
                      <Picture
                        className="h-full w-full object-cover"
                        imgClassName="w-full h-full object-cover"
                        source={`${hoverProSence}`}
                      ></Picture>
                    </SwiperSlide>
                  </Swiper>
                </div>
              )}
            </div>
            <div className={s.infoBox}>
              <Text
                html={getProductTag({
                  variant: currentVariant,
                  custom:
                    text.tagToDescription?.[tag]?.label ||
                    text.tagToDescription?.[tag] ||
                    '',
                })}
                className="min-h-[28px] pb-1 text-[14px] font-[600] text-[#FF5C00] md:min-h-[16px] md:text-[13px]"
                variant="paragraph"
              />
              <div className={s.nameBox}>
                <Text
                  autoTestTag={autoTestTag}
                  html={product.name}
                  className={cn(s.name, { [s.hover]: hover })}
                  variant="paragraph"
                />
                {hover && (
                  <div className={cn(s.swatch)}>
                    {product.options &&
                      product.options.map((opt) => {
                        if (opt.displayName == 'color') {
                          return opt.values.map((v, i) => {
                            return (
                              <ColorSwatch
                                key={`${opt.id}-${i}`}
                                imagePath={product.images[0]?.url}
                                className={s.color}
                                color={v.hexColors ? v.hexColors[0] : v.label}
                              />
                            )
                          })
                        }
                        return ''
                      })}
                  </div>
                )}
              </div>

              {solixFlexSku && (
                <div
                  className="mt-[16px] block text-[14px] font-[500] text-[#323232]"
                  dangerouslySetInnerHTML={{
                    __html: setHtmlRelativeLink({
                      html: solixFlexSku?.collectionTips?.replace(
                        'xx',
                        setRelativeLink({
                          link: `/products/${product.handle}`,
                        })
                      ),
                    }),
                  }}
                ></div>
              )}

              {availableForSale ? (
                <div className={s.price}>
                  {coupon?.title ? (
                    <p>
                      <span className="mr-1 text-[20px] font-semibold text-[#333] md:text-[16px]">
                        {sale_price}
                      </span>
                      <span className="text-[16px] font-medium text-[#AAA] line-through md:text-[13px]">
                        {price}
                      </span>
                    </p>
                  ) : (
                    <p>
                      <span className="mr-1 text-[20px] font-semibold text-[#333] md:text-[16px]">
                        {price}
                      </span>
                    </p>
                  )}
                  <div
                    className={cn(s.openPop, 'hidden bg-brand-color md:block')}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setOpenProductPop(true)
                      pageGTMEvent({})
                      pageGTMEvent({
                        event: 'uaEvent',
                        eventCategory: 'Product List Page_' + pageHandle, //品类页handle
                        eventAction: 'cart_pop',
                        eventLabel: 'collections_' + pageHandle, //即模块标题
                        nonInteraction: false,
                      })
                      pageGTMEvent({
                        event: 'ga4Event',
                        event_name: 'lp_button',
                        event_parameters: {
                          page_group: 'Product List Page_' + pageHandle,
                          position: 'collections_' + pageHandle,
                          button_name: 'cart_pop',
                        },
                      })
                    }}
                  >
                    <Picture
                      className="inline-block h-[22px] w-[22px]"
                      source="https://cdn.shopify.com/s/files/1/0508/1815/4652/files/icon_cart.svg?v=1694057353"
                    />
                  </div>
                  <div
                    className={cn(
                      'absolute w-full md:hidden',
                      hover ? 'opacity-100' : 'opacity-0',
                      'duration-300'
                    )}
                  >
                    <Button
                      cns={s}
                      className={cn(s.buyBtn)}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setOpenProductPop(true)
                        pageGTMEvent({
                          event: 'uaEvent',
                          eventCategory: 'Product List Page_' + pageHandle, //品类页handle
                          eventAction: 'cart_pop',
                          eventLabel: 'collections_' + pageHandle, //即模块标题
                          nonInteraction: false,
                        })
                        pageGTMEvent({
                          event: 'ga4Event',
                          event_name: 'lp_button',
                          event_parameters: {
                            page_group: 'Product List Page_' + pageHandle,
                            position: 'collections_' + pageHandle,
                            button_name: 'cart_pop',
                          },
                        })
                      }}
                    >
                      <Picture
                        className="inline-block h-[28px] w-[22px]"
                        source="https://cdn.shopify.com/s/files/1/0508/1815/4652/files/icon_cart.svg?v=1694057353"
                      />
                      <span className="leading-0 pl-[5px] text-[22px] font-semibold">
                        {coupon?.title ? sale_price : price}
                      </span>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className={s.price}>
                  <span className="pr-2 text-[22px] font-medium text-gray-300 md:text-[16px]">
                    {text?.soldOut}
                  </span>
                </div>
              )}
            </div>
            {sales && (
              <div className="absolute left-[24px] top-0 z-[3] h-[60px] w-[60px] text-base font-bold text-white md:left-[12px]  md:h-[45px] md:w-[45px]">
                <p className="relative left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 break-words px-1 py-[2px] text-center leading-[1.2] md:text-[12px]">
                  {sales}
                </p>
                {text?.saleBg && (
                  <Picture
                    alt="sales"
                    className="absolute left-0 top-0 w-full object-fill"
                    source={text?.saleBg}
                    src={text?.saleBg}
                  />
                )}
              </div>
            )}
            {compareBtnTxt && compareProducts?.length < 3 && (
              <Text
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (
                    compareProducts?.findIndex((p) => p.id === product.id) > -1
                  )
                    return
                  setCompareProducts &&
                    setCompareProducts([...compareProducts, product])
                }}
                className=" absolute right-[5%] top-[5%] text-[14px] text-[#00BEFA] underline md:text-[12px]"
                html={compareBtnTxt}
              ></Text>
            )}
          </div>
        )}
      </a>
      {openProductPop && (
        <ProductCardPop
          pageHandle={pageHandle}
          coupons={coupons}
          sku={currentVariant?.sku}
          sale_price={sale_price}
          price={price}
          sales={sales}
          product={product}
          setOpenProductPop={setOpenProductPop}
          metafields={metafields}
          text={text}
        ></ProductCardPop>
      )}
    </>
  )
}

export default ProductCard
