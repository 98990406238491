/* eslint-disable react-hooks/rules-of-hooks */
import Pagination from '@mui/material/Pagination'
import chunk from 'lodash.chunk'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useEffect, useMemo, useState, useRef } from 'react'
import styles from './Manuals.module.css'
import { Button, Text } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'

// 根据 category_name 聚合 faqs, 没有提供该字段的话统一聚合在”...“类别下
const groupFaqs = (list) => {
  const obj = {}
  list.map((item) => {
    if (item.category_name) {
      obj[item.category_name] = [...(obj[item.category_name] || []), item]
    } else {
      obj['...'] = [...(obj['...'] || []), item]
    }
  })
  return obj
}

const FaqItem = ({ data, s, faq, index, faqsOpen, handleFaqOpen, variant }) => {
  const answerRef = useRef(null)
  const { setHtmlRelativeLink } = useRelativeLink()
  return (
    <div key={`faq-${index}`}>
      <Button
        Component="div"
        variant="plain"
        className={s.item}
        onClick={() => handleFaqOpen(index)}
      >
        <Text
          style={{
            color: data?.styles?.txtColor || '',
          }}
          variant={variant || 'sectionSubHeading'}
          html={faq.question}
          className={s.question}
        />
        {faqsOpen[index] ? (
          <ChevronUpIcon className={cn(s.faqIcon, s?.faqIconClass)} />
        ) : (
          <ChevronDownIcon className={cn(s.faqIcon, s?.faqIconClass)} />
        )}
      </Button>

      <div
        className={cn(
          'overflow-hidden font-medium text-black/60 transition-all duration-300'
        )}
        style={{
          height: faqsOpen[index] ? answerRef.current?.clientHeight : 0,
          color: data?.styles?.txtColor || '',
        }}
      >
        <div ref={answerRef} className="pt-[24px] l:pt-[20px]">
          <Text
            html={setHtmlRelativeLink({ html: faq.answer })}
            className="text-[18px] font-medium l:text-[15px]"
          />
        </div>
      </div>

      {/* <div
        className={s.answer_div}
        style={{
          height: faqsOpen[index] ? answerRef.current?.clientHeight : 0,
        }}
      >
        <div ref={answerRef}>
          <Text
            // variant="paragraph"
            // https://stackoverflow.com/questions/31348533/react-dangerouslysetinnerhtml-only-working-correctly-when-it-is-used-on-a-div-ta
            //https://stackoverflow.com/questions/58266356/what-is-happening-such-i-receive-dangerouslysetinnerhtml-warning-and-empty-conte
            // 期间 cms 用过富文本编辑框，p > div(不合法) next.js 直接解析成了空，合法的嵌套 ok
            className={cn(s.answer, {
              [s.answerOpen]: faqsOpen[index],
            })}
            html={faq.answer}
          />
        </div>
      </div> */}

      <hr className={cn(s.line, s?.lineClass)} />
    </div>
  )
}

const Faqs = ({
  data,
  variant = '',
  className = '',
  s: gs = {},
  faqOpen = () => {},
  faqClose = () => {},
  openFirst = false,
  pageHandle = '',
}) => {
  if (!data?.items) return null
  const s = { ...styles, ...gs }
  // 分类
  const [groupedFaqs, categories] = useMemo(() => {
    const _ = groupFaqs(data.items)
    return [_, Object.keys(_)]
  }, [data.items])
  const [activeCategory, setActiveCategory] = useState(categories[0])

  // 分页
  const paginate =
    data.styles?.paginate?.show === undefined
      ? true //默认为 true
      : data.styles?.paginate?.show //如果有配置，则配置生效
  const chunkNumber = data.styles?.paginate?.count || 8
  const [activePage, setActivePage] = useState(1)
  const [currentPaginateCount, setCurrentPaginateCount] = useState()
  const chunkedFaqs = useMemo(
    () => chunk(groupedFaqs[activeCategory], chunkNumber),
    [activeCategory, chunkNumber, groupedFaqs]
  )

  useEffect(() => {
    // 切换类别时更新总页数
    setCurrentPaginateCount(chunkedFaqs.length)
    // 切换类别时更新页码到 1
    setActivePage(1)
  }, [activeCategory, chunkedFaqs])

  // 根据当前类别和当前页计算需要展示的 faqs
  const activeFaqs = useMemo(() => {
    if (paginate) {
      return chunkedFaqs[activePage - 1]
    }
    return groupedFaqs[activeCategory]
  }, [paginate, groupedFaqs, activeCategory, chunkedFaqs, activePage])

  // 当前展示 faq 的打开状态
  const [faqsOpen, setFaqsOpen] = useState(activeFaqs?.map(() => false))

  const handleFaqOpen = (index) => {
    // console.log('activeFaqs[index]:', activeFaqs[index])
    if (!faqsOpen[index]) {
      faqOpen({ ...activeFaqs[index] })
    } else {
      faqClose({ ...activeFaqs[index] })
    }
    setFaqsOpen((prev) =>
      prev.map((item, i) => {
        if (i === index) return !item
        return item
      })
    )

    window.dataLayer.push({ event_parameters: null })
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'lp_button',
      event_parameters: {
        page_group: 'Activity Page_' + pageHandle, //如果是首页，则传"Home Page"
        position: 'FAQ',
        button_name: 'answer', //取按钮文案，或能识别出是哪个按钮的文案,比如右上角关闭传close,社媒分享图标传对应平台名字如Facebook,图片切换则传对应展示图片的名字
      },
    })
  }

  useEffect(() => {
    if (openFirst) {
      setFaqsOpen(activeFaqs?.map((_, index) => (index === 0 ? true : false)))
    } else {
      setFaqsOpen(activeFaqs?.map(() => false))
    }
  }, [activeFaqs, openFirst])

  return (
    <div className={className}>
      <Text
        style={{
          color: data?.styles?.txtColor || '',
        }}
        variant={variant || 'sectionHeading'}
        className={cn(s.title, 'resetTitle')}
        html={data.title}
      />
      {data?.subTitle && (
        <Text
          variant="paragraph"
          style={{
            color: data?.styles?.txtColor || '',
          }}
          className={s.subTitle}
          html={data.subTitle}
        />
      )}

      {/* category */}
      {categories?.length > 1 && (
        <div className={s.categoriesWrap}>
          <div className={s.categories}>
            {categories.map((category) => {
              return (
                <button
                  key={`category-${category}`}
                  onClick={() => setActiveCategory(category)}
                  className={cn(s.faqCategoryItem, {
                    [s.activeCategory]: activeCategory === category,
                  })}
                >
                  <span
                    className={s.category}
                    dangerouslySetInnerHTML={{ __html: category }}
                  />
                </button>
              )
            })}
          </div>
        </div>
      )}
      {/* faqs */}
      <div className={s.container}>
        {activeFaqs?.map((faq, index) => {
          return (
            <FaqItem
              variant={variant}
              faq={faq}
              s={s}
              key={index}
              index={index}
              faqsOpen={faqsOpen}
              handleFaqOpen={handleFaqOpen}
              data={data}
            ></FaqItem>
          )
        })}
      </div>
      {/* paginate */}
      {paginate && currentPaginateCount > 1 && (
        <Pagination
          className="mx-auto w-max"
          count={currentPaginateCount}
          variant="outlined"
          shape="rounded"
          onChange={(_, page) => setActivePage(page)}
        />
      )}
    </div>
  )
}

export default Faqs
