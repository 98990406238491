import * as dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import { useTimer } from 'react-timer-hook'
import { Picture, Text } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Cta, Price } from '@components/product'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { useState, useEffect, useRef } from 'react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { formatPrice } from '@commerce/product/use-price'
import { normalizeStorefrontProduct as normalize } from '@components/normalize'

import 'swiper/css'
import 'swiper/css/navigation'

import cn from 'classnames'
import s from './index.module.css'
import useCoupons from '@lib/hooks/useCoupon'
import useProduct from '@shopify/product/use-product'

const Index = ({ pageHandle, data, dataKey, category }) => {
  const targetRef = useRef(null)

  const { locale } = useRouter()
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isImpression, setIsImpression] = useState(false)

  const { data: result } = useProduct({
    handles: data?.products?.map((product) => product?.handle).join(','),
  })

  const { coupons } = useCoupons(data.products?.map((p) => p.sku))

  const products = data.products
    ?.map((product) => normalize(product, result, coupons, data))
    ?.filter((p) => p)

  useEffect(() => {
    if (targetRef.current) {
      const targetPosition =
        targetRef.current.getBoundingClientRect().top + window.pageYOffset
      const windowHeight = window.innerHeight
      // 判断是否滚动到目标元素的位置
      if (
        scrollPosition > targetPosition - windowHeight &&
        !isImpression &&
        products &&
        scrollPosition
      ) {
        // 在此执行您的操作
        pageGTMEvent({ event_parameters: null })

        let items = products.map((item, index) => {
          return {
            item_id: item?.sku,
            item_name: item?.label,
            item_brand: item?.brand,
            item_category: item?.category,
            item_variant: atobID(item?.variantId),
            price: item?.price,
            index: index, //表示该产品在列表中的展示顺序
          }
        })
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'view_item_list',
          event_parameters: {
            page_group:
              pageHandle == 'home'
                ? 'Home Page'
                : 'Product Detail Page_' + pageHandle,
            position: '', //未特殊说明时可不要
            currency: products[0]?.currencyCode,
            item_list_id: '', //未特殊说明时可不要
            item_list_name: category
              ? 'flash_sale_collection'
              : pageHandle == 'home'
              ? 'flash_sale_home'
              : 'flash_sale_listing', //传该模块的小标题
            items,
          },
        })
        setIsImpression(true)
      }
    }
  }, [isImpression, scrollPosition, data, products, pageHandle, category])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleJump = (item, index) => {
    pageGTMEvent({ event_parameters: null })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group:
          pageHandle == 'home'
            ? 'Home Page'
            : 'Product Detail Page_' + pageHandle,
        action: 'Img/Title_' + '', //可不要
        position: '', //未特殊说明时可不要
        currency: products[0]?.currencyCode,
        item_list_id: '', //未特殊说明时可不要
        item_list_name: category
          ? 'flash_sale_collection'
          : pageHandle == 'home'
          ? 'flash_sale_home'
          : 'flash_sale_listing', //传该模块的小标题
        items: [
          {
            item_id: item?.sku,
            item_name: item?.label,
            item_brand: item?.brand,
            item_category: item?.category,
            item_variant: atobID(item?.variantId),
            price: item?.price,
            index: index, //表示该产品在列表中的展示顺序
          },
        ],
      },
    })
  }

  return (
    <section className={cn('layer', s.flashSale)} ref={targetRef}>
      <style>
        {data?.isTheme &&
          `
        .${s.flashSale} .primary {
          background-color: #FF9128;
          border: #FF9128;
        }
        .${s.flashSale} .mask {
          background-color: #FFA24A;
        }
        .${s.flashSale} .secondary {
          color: #000;
          background-color: white;
          border: 1px solid #FF9128;
        }
        .${s.flashSale} .secondary:hover {
          color: white;
        }

        .${s.swiper} .swiper-slide {
          height: auto;
        }

        .${s.swiper} .swiper-button-prev,
        .${s.swiper} .swiper-button-next {
          top: 40%;
          background-color: #D9D9D9; 
          color: #ffffff; 
          font-size: 24px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }

        .${s.swiper} .swiper-button-next:after, 
        .${s.swiper} .swiper-button-prev:after {
          font-size: 18px;
        }

        .${s.swiper} .swiper-button-prev:hover,
        .${s.swiper} .swiper-button-next:hover {
          opacity: 0.5;
        }

        .${s.swiper} .swiper-button-prev.swiper-button-disabled,
        .${s.swiper} .swiper-button-next.swiper-button-disabled {
          opacity: 0.5;
          cursor: not-allowed; 
        }

        @media (max-width:769px) {
          .swiper-button-prev,
          .swiper-button-next{
            display: none;
          }
        },
      `}
      </style>
      <div className="content">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-end gap-[20px] md:gap-[10px]">
            {data?.imgLeft && (
              <Picture
                className="h-[90px] w-[75px] md:h-[37px] md:w-[31px]"
                source={`${data?.imgLeft}`}
              />
            )}
            <Text
              html={data?.title}
              className="text-center text-[40px] font-semibold md:text-[24px]"
              variant="sectionHeading"
            />
            {data?.imgRight && (
              <Picture
                className="h-[90px] w-[75px] md:h-[37px] md:w-[31px]"
                source={`${data?.imgRight}`}
              />
            )}
          </div>

          {data?.subtitle && (
            <Text
              html={data?.subtitle}
              className="text-center text-[24px] font-medium text-[#666] md:text-[16px]"
              variant="paragraph"
            />
          )}
        </div>

        <Swiper
          loop
          autoplay={{
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          initialSlide={2}
          navigation={true}
          className={s.swiper}
          breakpoints={{
            375: {
              slidesPerView: 1.2,
              spaceBetween: 10,
              centeredSlides: false,
            },
            1280: {
              slidesPerView: 3.3,
              spaceBetween: 16,
              centeredSlides: true,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {products?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="flex h-full flex-col">
                <div
                  className={cn(
                    'flex flex-1 flex-col overflow-hidden rounded-[20px] bg-[#fff] md:rounded-[10px]',
                    { ['pt-[46px]']: item?.imgWidth != 'full' }
                  )}
                >
                  <Discount data={data} product={item} />
                  <a
                    className={cn(s.imgWrap, {
                      [s.imgWrapFull]: item?.imgWidth == 'full',
                    })}
                    onClick={() => handleJump(item, index)}
                    href={`${locale == 'us' ? '' : '/' + locale}/products/${
                      item?.handle
                    }?ref=${
                      data?.ref || `${pageHandle}-${dataKey}`
                    }&variant=${atobID(item?.variantId)}`}
                  >
                    <Picture
                      className="flex h-full w-full"
                      source={`${item?.image}, ${item?.mobileImage} 769`}
                    />
                  </a>
                  <div
                    className={cn('flex flex-1 flex-col pt-[16px] ', {
                      [s.border]: item?.imgWidth == 'full',
                    })}
                  >
                    <Text
                      html={item?.title}
                      className="px-[45px] pb-[14px] text-center text-[20px] font-medium text-[#666] md:px-[20px] md:text-[18px]"
                      variant="paragraph"
                    />
                    {item?.endTime && <CountDown item={item} data={data} />}
                  </div>

                  {item?.imgWidth !== 'full' && (
                    <>
                      <hr className="mx-[20px] py-[6px]" />
                      <div className="flex justify-between px-[20px] pb-[4px] text-[15px] font-semibold">
                        <Text
                          html={item?.sold}
                          className="text-[#000]"
                          variant="paragraph"
                        />
                        <div className="flex gap-[5px]">
                          <Text
                            html={data?.stock}
                            className="text-[#FF9128]"
                            variant="paragraph"
                          />
                          <Text
                            html={item?.quantityAvailable}
                            className="text-[#FF9128]"
                            variant="paragraph"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex justify-center gap-[5px] pb-[22px] pt-[43px] font-semibold md:py-[23px]">
                  <Price
                    product={item}
                    copy={data}
                    s={{
                      soldOut: 'text-[32px] md:text-[32px]',
                      price: 'text-[32px] text-[#FF9128] md:text-[32px]',
                      basePrice:
                        'text-[#BABABA] text-[25px] line-through md:text-[25px]',
                    }}
                  />
                </div>

                <Cta
                  copy={data}
                  products={[item]}
                  pageHandle={pageHandle}
                  gtmParam={{
                    itemListName: category
                      ? 'flash_sale_collection'
                      : pageHandle == 'home'
                      ? 'flash_sale_home'
                      : 'flash_sale_listing', //传该模块的小标题
                  }}
                  buttons={
                    item?.buttons ||
                    data?.buttons || [{ type: 'buyNow', theme: 'primary' }]
                  }
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Index

export const Discount = ({ data, product }) => {
  const { locale } = useRouter()
  if (!product?.discountValue || !product?.availableForSale) return null
  return (
    <div
      className={cn(s.discountWrap, { [s.discountSort]: locale == 'eu-de' })}
    >
      {product?.discountValue && (
        <span className={s.price}>
          {product?.discountType === 'fixed_amount'
            ? formatPrice({
                amount: parseInt(product.discountValue),
                locale,
                currencyCode: product.currencyCode,
                maximumFractionDigits: 0,
              })
            : parseInt(product.discountValue) + '%'}
        </span>
      )}
      <span className={s.off}>{data.off}</span>
    </div>
  )
}

export const CountDown = ({ item, data }) => {
  const { hideDays, endTime, starTime } = item

  let timestamp = dayjs(endTime).format('YYYY-MM-DDTHH:mm:ssZ')

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(timestamp),
  })

  if (new Date() > new Date(endTime) || new Date() < new Date(starTime))
    return null

  return (
    <div className={cn(s.countDown, 'countDown')}>
      {data?.ends && (
        <Text
          html={data?.ends}
          className="pb-[5px] text-[16px] font-medium text-[#999]"
          variant="paragraph"
        />
      )}
      <div className="flex items-center gap-[5px] font-semibold leading-[28px]">
        {hideDays && days > 0 && (
          <>
            <div>
              <p>{days.toString().padStart(2, '0')}</p>
            </div>
            :
          </>
        )}
        <div>
          <span>
            {!hideDays
              ? (days * 24 + hours).toString().padStart(2, '0')
              : hours.toString().padStart(2, '0')}
          </span>
        </div>
        :
        <div>
          <span>{minutes.toString().padStart(2, '0')}</span>
        </div>
        :
        <div>
          <span>{seconds.toString().padStart(2, '0')}</span>
        </div>
      </div>
    </div>
  )
}
